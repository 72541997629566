.workproject-card {
  position: relative;
  .card {
    background-color: transparent;
    border-color: transparent;
  }

  &-title {
    font-size: 3rem !important;
    font-weight: 900 !important;
    -webkit-text-stroke: 2px black;
  }

  @media screen and (max-width: 990px) {
    &-title {
      font-size: 2rem !important;
      font-weight: 500 !important;
    }
  }
  &-topimage {
    background-color: transparent;
    img {
      max-height: 300px;
      object-fit: cover;
      border-radius: 15px 15px 3px 3px;
    }
  }

  &-body {
    background: #212529;
    border-radius: 3px 3px 15px 15px;
    padding: 10px;
  }
}
