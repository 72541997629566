.tech-stack-content {
  padding-bottom: 4rem;
}
.tech-stack-content-card {
  .tech-stack-content-card-img {
    padding: 1rem;
    align-self: center;
    max-width: 160px;
  }
  .tech-stack-card-des{
    list-style-type: none;
  }
}
