// common heading
$section-heading-font-size: 5rem;
$section-heading-font-weight: 900;
$section-heading-font-size-md: 4rem;
$section-heading-font-weight-md: 600;
$section-heading-font-size-sm: 2rem;
$section-heading-font-weight-sm: 500;

// colors
$vein-fur-blue: #8dd6fe;
$vein-fur-white: #f8f9fb;
$vein-fur-purple: #560e9d;
$vein-paw-orange: #e85131;
$vein-eye-red: #ff2a32;
$vein-eye-black: #000000;
$card-back-text-color: #ffffcc;

// blue-color themed color palette
// Cerise + Blue Crayola + Royal Dark Blue Color Palette
$palette-blue-20-1: #0a2463;
$palette-blue-20-2: #3e92cc;
$palette-blue-20-3: #fffaff;
$palette-blue-20-4: #d8315b;
$palette-blue-20-5: #1e1b18;

// Light Salmon + French Pink + Baby Blue Color Palette
$palette-blue-19-1: #70d6ff;
$palette-blue-19-2: #ff70a6;
$palette-blue-19-3: #ff9770;
$palette-blue-19-4: #ffd670;
$palette-blue-19-5: #e9ff70;

// media break points
$bs_xs: 575;
$bs_sm: 767;
$bs_md: 991;
$bs_lg: 1199;
$bs_xl: 2000;
$bs_warning: #ffc107;

// Navbar left
$nav-left-bg-clr: $palette-blue-19-1;
$nav-top-bg-clr: $palette-blue-19-1;
$portfolio-icon-size: 6rem;
// configs - Home - navbar
$navbar-top-height: 5rem;
$navbar-fontsize: 2.2rem;
$navbar-fontsize-sm: 1.2rem;
$navbar-brand-fontsize: 3.2rem;
$navbar-item-margin-right: 2rem;

// configs - Home - About me
$aboutMe-heading-fontSize: 7rem;
$flippingCard-height: 50rem;
$flippingCard-height-sm: 30rem;
$flippingCard-height-xl: 60rem;
$flippingCard-front-fontsize: 5.5rem;
$flippingCard-front-fontsize-sm: 3rem;
$flippingCard-front-fontsize-xl: 6.5rem;
$flippingCard-back-fontsize: 2.5rem;
$flippingCard-back-fontsize-sm: 1rem;
$flippingCard-back-fontsize-xl: 3.5rem;

// config - Resume - page title
$resumePageTItle-height: 90vh;

// shudu
$shuduCell-bgColor-changable: #5787f8;
$shuduCell-bgColor-unchangable: #3c73f5;
$shuduCell-textColor-unchangable: #ffffcc;
$shuduCell-bgColor-inDomain: #44c4f7;
$shuduCell-size-normal: 5rem;
$shuduBoard-total-width-normal: 45rem;
$wolfAssistant-speechBubble-bgColor: rgba(
  $color: #00aabb,
  $alpha: 0.5,
);
