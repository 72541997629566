@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin onHoverScale($factor) {
  @media (hover: hover) {
    &:hover {
      transform: scale($factor);
    }
  }
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin boxShadow {
  box-shadow: 1rem 1rem 4rem rgba($color: $vein-eye-black, $alpha: 0.3);
}

@mixin resumePageSectionBackgroundColor {
  background-image: linear-gradient(
    45deg,
    rgba($vein-fur-blue, 0.7),
    rgba(cyan, 0.3)
  );
}
/* Media queries
0-320px: small phone
320-600: Phone
>2000: xl

*/
@mixin responsive($breakpoint) {
  @if $breakpoint == minimal {
    @media (max-width: 20em) {
      @content;
    }
  }
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (min-width: 125em) {
      @content;
    }
  }
  @if $breakpoint == ideal {
    @media (max-width: 46.87em) {
      @content;
    }
  }
}
