* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Comic Sans MS", "Lucida Sans Unicode", "Courier New", sans-serif;
  // background-color: $palette-blue-19-1;
}
hr {
  margin: 0;
}
