#bibliograph {
  min-height: 90vh;
  // border-top: 6px dotted black;
  // display: grid;
  // grid-template-columns: repeat(5, 1fr);
  // grid-template-rows: 5rem 1fr;
  // grid-template-areas:
  //   ". name name name name"
  //   "selfie description description  description description";

  // .my-name {
  //   // background-color: $palette-blue-19-3;
  //   // color: $palette-blue-19-5;
  //   align-self: end;
  //   font-size: 2rem;
  //   border-bottom: 3px solid black;
  //   grid-area: name;
  // }

  .my-pic {
    padding: 1rem 0;
    grid-area: selfie;

    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 100%;
      height: $portfolio-icon-size;
      width: $portfolio-icon-size;
      object-fit: contain;
      transition: transform 100ms ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  #bibli-title {
    font-size: 5rem;
    font-weight: 1000;
  }

  .my-bibli {
    .title {
      font-size: 3rem;
    }
    grid-area: description;
    align-self: center;
    padding: 1rem 0.4rem;
    font-size: 1rem;
    p {
      margin: 0.5rem 0;
    }
  }
}
