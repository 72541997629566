.spinner {
  justify-items: center;
}
.loader {
  justify-self: center;
  align-self: center;
  position: relative;
  width: 5rem;
  height: 5rem;
  background: linear-gradient(to right, #fff 20%, #0000 21%);
  color: aliceblue;
  background-repeat: repeat-x;
  background-size: 36px 8px;
  background-position: 9px bottom;
  animation: moveX 0.5s linear infinite;
}
.loader::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: aqua;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 0.5s linear infinite;
}

@keyframes moveX {
  0%,
  25% {
    background-position: 10px bottom;
  }
  75%,
  100% {
    background-position: -30px bottom;
  }
}
@keyframes rotate {
  0%,
  25% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  75%,
  100% {
    transform: translate(-55%, -55%) rotate(90deg);
  }
}
