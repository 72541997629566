.social-medias {
  display: flex;
  justify-content: space-around;
  border-top: dotted black 2px;
  &-icon {
    font-size: 2rem;
  }
  a {
    &:visited {
      color: inherit;
    }
    &:hover {
      color: $vein-paw-orange;
    }
  }
}
